import React from "react";
import { ArrowLink } from "~/components/ui/arrow-button";
import { Paragraph } from "~/components/ui/typography";
import { Icon } from "~/components/ui/icons";
import { Container } from "./container";
import type { Cta } from "~/types";
import { getItemLocalStorage, setItemLocalStorage } from "~/utils/misc";
import { motion } from "framer-motion";

interface Props {
	position?: "top" | "default";
	id: string;
	tagline?: string;
	children?: string;
	cta?: Cta;
	trackPosition?: string;
}

export function Banner({
	position = "top",
	tagline,
	children,
	cta,
	id,
	trackPosition = "banner",
}: Props) {
	const isTopBanner = position === "top";
	const defaultShowValue = !isTopBanner ? true : false;
	const [show, setShow] = React.useState(defaultShowValue);

	// For top banner, show the banner if user hasn't closed it
	// or it has been closed more than a day
	React.useEffect(() => {
		if (!isTopBanner) {
			return;
		}

		const closingDateFromLS = getItemLocalStorage(id);

		if (!closingDateFromLS) {
			return setShow(true);
		}

		const closingDate = new Date(closingDateFromLS);

		const aDayFromClosingDate = closingDate.setHours(
			closingDate.getHours() + 24
		);

		if (new Date(aDayFromClosingDate) > new Date()) {
			return setShow(false);
		}

		setShow(true);
	}, [id, position, isTopBanner]);

	function close() {
		setShow(false);

		setItemLocalStorage(id, new Date());
	}

	return show ? (
		<>
			{isTopBanner ? (
				<motion.div
					initial={{ maxHeight: 0 }}
					animate={{ maxHeight: "100px" }}
					transition={{
						ease: "easeOut",
						duration: 0.5,
					}}
				>
					<div className="dark flex items-center justify-between bg-grey-100 px-3 py-4">
						<div className="flex flex-grow items-center justify-center gap-4">
							<Paragraph
								className="hidden uppercase xl:block"
								fontWeight="font-semibold"
								color="theme-primary"
								size="body-xsmall"
							>
								{tagline}
							</Paragraph>
							<Paragraph
								className="hidden xl:block"
								size="body-xsmall"
							>
								{children}
							</Paragraph>
							{cta?.title && cta?.url ? (
								<ArrowLink
									className="!block text-center !text-xs xl:!inline-flex [&>svg]:ml-2 [&>svg]:inline"
									to={cta?.url}
									trackingText={cta?.title}
									trackingPosition={trackPosition}
									size="dense"
								>
									<span className="hidden xl:flex">
										{cta?.title}
									</span>
									<span className="inline xl:hidden">
										{children}
									</span>
								</ArrowLink>
							) : null}
						</div>
						<button onClick={close}>
							<span className="sr-only">Close banner</span>
							<Icon name="close-button" color="secondary" />
						</button>
					</div>
				</motion.div>
			) : (
				<Container noPadding>
					<div className="bg-secondary flex rounded-lg py-5 px-5 lg:px-6">
						<div className="flex flex-col gap-2 lg:flex-row lg:items-center lg:justify-center lg:gap-5">
							{tagline ? (
								<Paragraph
									size="heading-6"
									fontWeight="font-semibold"
									className="uppercase"
									color="theme-primary"
								>
									{tagline}
								</Paragraph>
							) : null}

							<Paragraph size="body-small">{children}</Paragraph>

							{cta?.title && cta?.url ? (
								<ArrowLink
									className="whitespace-nowrap"
									to={cta.url}
									trackingPosition="banner"
									size="dense"
								>
									{cta.title}
								</ArrowLink>
							) : null}
						</div>
					</div>
				</Container>
			)}
		</>
	) : null;
}
